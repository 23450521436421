import css from 'styled-jsx/css';

export const transformationPlanStyle = css`
    :global(body) {
        background-color: hsl(4, 20%, 96%);
    }
    .hero > div,
    .features {
        width: 100%;
        padding: 24px;
        margin: 0 auto;
        max-width: 1240px;
        box-sizing: border-box;

        & ul {
            padding: 0;
            list-style: none;
            margin-top: 16px;

            & li {
                padding-left: 24px;
                background: url('/images/store/tick.svg');
                background-repeat: no-repeat;
                background-position: 0 2px;
                margin-top: 8px;
            }
        }
    }

    .hero {
        background: linear-gradient(hsl(4, 10%, 86%), hsl(4, 20%, 96%));

        & > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        & h1 {
            margin-top: 16px;
        }

        & .text {
            max-width: 480px;
        }

        & :global(.testimonial-slideshow) {
            max-width: 400px;
            width: 100%;
            margin-top: 40px;
        }

        & button {
            margin-top: 16px;
        }
    }

    .features {
        & > div {
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            justify-content: center;
            flex-direction: column-reverse;
        }

        & h2 {
            font-size: 22px;
            line-height: 32px;
            margin-top: 16px;
        }

        & .image,
        & .recipe-images {
            max-width: 300px;
            width: 100%;
            margin-top: 24px;

            & img {
                display: block;
            }
        }

        & .image {
            overflow: hidden;
            border-radius: 3px;
        }

        & .recipe-images {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            & :global(.gatsby-image-wrapper) {
                width: calc(50% - 8px);
                border-radius: 3px;

                &:nth-child(n + 3) {
                    margin-top: 16px;
                }
            }
        }

        & .text {
            max-width: 400px;
        }
    }

    hr.narrow {
        margin: 56px auto;
        width: calc(100% - 64px);
        max-width: 600px;
    }

    .feature-cta {
        margin: 32px auto 0;
        display: flex;
        justify-content: center;
    }

    .how-it-works {
        margin-top: 32px;

        & h2 {
            text-align: center;
        }
        
        & > div {
            background-image: url('/images/store/wave.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;

            & > div {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 110px 32px;
                margin-top: 32px;
                margin: 0 auto;
                max-width: 1240px;
                box-sizing: border-box;

                & > div {
                    width: 100%;
                    //max-width: 400px;
                    box-sizing: border-box;
                    background-color: #fff;
                    overflow: hidden;
                    padding: 16px;
                    border-radius: 3px;

                    & + div {
                        margin-top: 34px;
                    }
                    &.three-col {
                      font-weight: bold;
                      & > p > span {
                        color: #f44336;
                        background: url('/images/store/tick.svg');
                        background-repeat: no-repeat;
                        background-position: 0 2px;
                        padding-right: 20px;
                      }
                    }
                }
            }

            & h3 {
                margin: 0;

                & > span {
                    display: inline-block;
                    background-color: #263238;
                    color: #fff;
                    border-radius: 50%;
                    width: 24px;
                    text-align: center;
                    margin-right: 4px;
                }
            }

            & p {
                margin-bottom: 0;
            }
        }
    }

    .portal-images {
        & > :global(div) {
            margin: 10px auto 0;
            max-width: 325px;
        }
    }

    .gutter-container {
        padding: 40px 0 32px;

        & > div {
            padding: 0 24px;
            max-width: 680px;
            margin: 0 auto;
        }
    }

    .hero-cta {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 3px;
        padding: 24px;
        margin-top: 24px;

        & > div:first-of-type {
            min-width: 192px;
        }

        & .price {
            margin-top: 0;

            & ~ p {
                margin: 0;
            }
        }

        & button {
            margin-top: 0;
        }

        & + :global(form) {
            margin-top: 24px;
        }
    }

    .footer-cta {
        background-color: #fff;
        border-radius: 3px;
        max-width: 680px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 40px;

        & p {
            margin-top: 0;
        }

        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    & .price {
        color: #f44336;
        opacity: 1;
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 0;

        & strike,
        & span {
            font-size: 20px;
            line-height: 32px;
            color: rgba(38, 50, 56, 0.8);
        }

        & span {
            color: #f44336;
        }

        & ~ p {
            margin: 0;
        }
    }

    .faq-heading {
        text-align: center;
        margin: 48px 0 16px;

        & + :global(div) {
            border-top: 0;
        }
    }

    @media (min-width: 650px) {
        .hero {
            & > div {
                justify-content: space-between;
                align-items: center;
            }

            & :global(.testimonial-slideshow) {
                width: calc(50% - 24px);
            }

            & .text {
                flex: 1;
                margin-right: 48px;
            }
        }

        .features {
            & > div {
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: row;
            }

            & .image,
            & .recipe-images {
                width: calc(50% - 24px);
                margin-top: 0;
            }

            & .text {
                flex: 1;
                margin-left: 48px;
                max-width: 600px;
            }

            & h2 {
                margin-top: 0;
            }
        }

        .portal-images {
            display: flex;
            justify-content: space-between;

            & :global(div) {
                width: 30%;
            }
        }

        .footer-cta {
            padding: 40px 48px;
        }
    }

    @media (min-width: 800px) {
        .hero > div,
        .features {
            padding: 32px;
        }

        .how-it-works {
            & > div > div {
                justify-content: space-between;

                & > div {
                    width: calc(100% / 2 - 64px / 2);
                    margin-bottom:25px;
                    & + div {
                        margin-top: 0;
                    }
                    &.three-col {
                      width: calc(100% / 3 - 64px / 3);
                    }
                }
            }
        }

        .gutter-container {
            & > div {
                padding: 0 24px;
            }
        }
    }
`;
